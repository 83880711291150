import React from "react";

const SliderCard = ({ title, desc }) => {
	return (
		<div className="max-w-sm h-44 2xl:h-52 mb-10 bg-primaryDark text-white p-4 rounded-lg">
			<h3 className="text-xl sm:text-2xl font-semibold text-primary">
				{title}
			</h3>
			<p className="text-base 2xl:text-xl pt-2">{desc}</p>
		</div>
	);
};

export default SliderCard;
