export const accordiansdata = [
	{
		title: "What industries do you provide your hotel cleaning services?",
		content:
			"We service all commercial industries including hotels, resorts, accommodation establishments and many more. Our accommodation cleaning services are adaptable to the specific needs and requirements of the venue.",
	},
	{
		title: "What type of hotel cleaning services do you offer?",
		content:
			"We offer a wide range of hotel cleaning services, such as ongoing routine cleaning, deep cleaning, kitchen cleaning, carpet cleaning, floor scrubbing, toilets and amenities cleaning, upholstery cleaning, window cleaning, sanitisation, common area cleaning, and many more. We use industry-approved techniques and equipment to achieve outstanding results.",
	},
	{
		title: "Can you accommodate specific cleaning requirements or preferences?",
		content:
			"Absolutely. We understand that each hotel or accommodation establishment may have unique cleaning requirements or preferences. Our team works closely with you to understand your specific needs and tailor our cleaning services accordingly. We take into account any special instructions, preferences, or sensitivities to deliver a customised cleaning program that aligns with your brand standards.",
	},
	{
		title:
			"How do you ensure the safety and security of guest belongings during cleaning?",
		content:
			"The safety and security of guest belongings are of utmost importance to us. Our team follows strict protocols to protect guest privacy and secure their personal belongings during the cleaning process. We prioritise professionalism and discretion to provide a worry-free experience for your guests.",
	},
	{
		title: "Can you provide cleaning services during peak occupancy periods?",
		content:
			"Yes, we understand that hotels and accommodations experience peak occupancy periods. Our team is equipped to handle the increased cleaning demands during busy times. We work closely with you to create a cleaning schedule that accommodates peak occupancy periods, ensuring that your property remains clean and presentable at all times.",
	},
];
