export const ourServices = [
	{
		img: "./images/our-services/room-cleaning.webp",
		heading: "Room Cleaning",
		text: "Our professional team provides thorough room cleaning services to ensure a clean and comfortable environment.",
	},
	{
		img: "./images/our-services/carpet-steam-cleaning.webp",
		heading: "Carpet Steam Cleaning",
		text: "We effectively remove dirt, stains, and odors from carpets using advanced steam cleaning techniques.",
	},
	{
		img: "./images/our-services/uphostery-cleaning.webp",
		heading: "Upholstery Cleaning",
		text: "Restore the beauty and cleanliness of your furniture with our expert upholstery cleaning services.",
	},
	{
		img: "./images/our-services/high-pressure-steam-cleaning.webp",
		heading: "High Pressure and Steam Cleaning",
		text: "Powerful cleaning results for surfaces like driveways, walkways, and industrial equipment.",
	},
	{
		img: "./images/our-services/post-construction-cleaning.webp",
		heading: "Post Construction or Refurbishment Cleaning",
		text: "Comprehensive cleaning services after construction or refurbishment projects to ensure a clean and safe space.",
	},
	{
		img: "./images/our-services/house-keeping-supplies.webp",
		heading: "Housekeeping Supplies",
		text: "A wide range of high-quality housekeeping supplies to meet your cleaning needs.",
	},
	{
		img: "./images/our-services/cleaning-supplies.webp",
		heading: "Cleaning Supplies",
		text: "Comprehensive selection of cleaning supplies including chemicals, tools, and paper products.",
	},
	{
		img: "./images/our-services/spa-care-sanitation.webp",
		heading: "Spa Care and Sanitation",
		text: "Maintain a clean and hygienic spa environment with our spa care and sanitation services.",
	},
	{
		img: "./images/our-services/pest-control.webp",
		heading: "Pest Control",
		text: "Effective pest control services to eliminate and prevent pests from invading your premises.",
	},
	{
		img: "./images/our-services/furniture-fittings.webp",
		heading: "Furniture and Fittings",
		text: "Wide range of stylish and functional furniture and fittings for hotels and hospitality establishments.",
	},
	{
		img: "./images/our-services/public-area-cleaning.webp",
		heading: "Public Area Cleaning",
		text: "Comprehensive cleaning services for public areas to create a clean and welcoming environment.",
	},
	{
		img: "./images/our-services/amenities.webp",
		heading: "Amenities",
		text: "Enhance the guest experience with our carefully selected range of amenities for hotels.",
	},
	{
		img: "./images/our-services/commercial-cleaning.webp",
		heading: "Commercial Cleaning",
		text: "Professional and reliable cleaning solutions for various commercial spaces and industries.",
	},
	{
		img: "./images/our-services/floor-cleaning.webp",
		heading: "Floor Care",
		text: "Comprehensive floor care services to maintain the cleanliness and appearance of your floors.",
	},
];

export default ourServices;
