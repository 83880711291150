export const servicebenefits = [
	{
		title: "Superior Service Quality",
		desc: "We are the best in hotel cleaning services. We combine industry expertise, high quality equipment, non-toxic chemicals, and exceptional customer service to deliver outstanding results.",
	},
	{
		title: "Consistent Results",
		desc: "We focus on the small details to leave a big impact on your venue. Our thorough quality assurance process ensures consistent quality and attention to detail in every hotel cleaning service.",
	},
	{
		title: "Affordable Prices",
		desc: "We offer our clients luxury on a budget. Get more for your money with our everyday affordable prices. We provide transparent, detailed quotes with no hidden costs for all hotel cleaning services.",
	},
	{
		title: "Industry Compliance",
		desc: "We give you peace of mind with our compliance to all industry laws and regulations. Our team uses industry-approved equipment and chemicals to deliver a safe and compliant hotel cleaning service.",
	},
	{
		title: "Qualified Teams",
		desc: "Our local hotel cleaners are fully qualified and licenced with extensive industry experience. Our team is the best in hotel cleaning solutions, ensuring a safe, hygienic and efficient housekeeping service.",
	},
	{
		title: "Flexible Scheduling",
		desc: "Minimising disruptions to your hospitality venue is our priority. We work with you to develop a service timeframe that is convenient and aligns with your opening hours or operational activities.",
	},
];
