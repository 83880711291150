import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/common/ScrollToTop";
import Footer from "./components/layouts/Footer";

function App() {
	let location = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, [location]);

	return (
		<HelmetProvider>
			<Navbar />
			<Outlet />
			<ScrollToTop />
			<Footer />
		</HelmetProvider>
	);
}

export default App;
