import { Formik } from "formik";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdCalendarMonth } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import ContactCard from "../components/common/ContactCard";
import SelectField from "../components/common/SelectField";
import TextAreaField from "../components/common/TextAreaField";
import TextInputField from "../components/common/TextInputField";
import { contactsData } from "../data/contactsData";
import { serviceOptions, timeHours, timeMode } from "../data/formSelectOptions";
import { Helmet } from "react-helmet-async";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase.config";
import { IoClose } from "react-icons/io5";
import Paragraph from "../components/common/Paragraph";
import HeroImgTextOverlay from "../components/HeroImgTextOverlay";

const Contacts = () => {
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const sendFormDataToFirebaseDatabase = async (values, resetForm) => {
		try {
			await addDoc(collection(db, "users", "id"), {
				// here values represens all the form values
				...values,
				// date & time when user data is send to firebase database i.e. form is submitted
				timestamp: serverTimestamp(),
			});
			// after form data is successfully send to database
			setSuccessMessage("Form is submitted successfully!!!");
			resetForm();
		} catch (error) {
			setErrorMessage(
				"⚠ ⚠ Uh-oh! It seems there was an error while submitting the form. Please double-check your inputs and try again later."
			);
			console.log(" ⚠ Errors occur during form submision!!!", error.message);
		}
	};
	return (
		<>
			<Helmet>
				<title>Contact US</title>
				<meta
					name="description"
					content="Get in touch with us for all your service inquiries."
				/>
				<meta
					name="keywords"
					content="contact ONS Solutions, get in touch, customer support, inquiries, contact information, reach us, communication, hospitality services"
				/>
				<link rel="canonical" href="https://onssolution.netlify.app/contacts" />

				{/* Facebook Meta Tags */}
				<meta property="og:title" content="Contact US - ONS Solutions" />
				<meta
					property="og:description"
					content="Get in touch with us for all your service inquiries."
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://onssolution.netlify.app/contact-thumbnail.png"
				/>
				<meta
					property="og:image"
					content="https://onssolution.netlify.app/contact-thumbnail.png"
				/>

				{/* Twitter Meta Tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="Contact US - ONS Solutions" />
				<meta
					name="twitter:description"
					content="Get in touch with us for all your service inquiries."
				/>
				<meta
					name="twitter:image"
					content="https://onssolution.netlify.app/contact-thumbnail.png"
				/>
			</Helmet>

			<HeroImgTextOverlay
				img="/images/contact/heroimg.webp"
				heading="Get in Touch with Us"
				text="Reach out | We'll assist"
			/>

			<div className="max-w-6xl mx-auto px-4 xl:px-0 mt-7 md:mt-14">
				<section className="flex items-baseline justify-between gap-7 lg:gap-4 flex-col lg:flex-row">
					{contactsData.map((contact) => (
						<ContactCard
							key={uuidv4()}
							icon={contact.icon}
							heading={contact.heading}
							details={contact.details}
						/>
					))}
				</section>

				{/* form */}
				<Formik
					initialValues={{
						name: "",
						service: "",
						address: "",
						phone: "",
						email: "",
						date: new Date(),
						time: "",
						timeMode: "",
						message: "",
					}}
					validationSchema={Yup.object({
						name: Yup.string().required("Required"),
						service: Yup.string().required("Required"),
						address: Yup.string().required("Required"),
						phone: Yup.string().required("Required"),
						email: Yup.string()
							.email("Invalid email address")
							.required("Required"),
						date: Yup.date().required("Required"),
						time: Yup.string().required("Required"),
						timeMode: Yup.string().required("Required"),
					})}
					onSubmit={(values, { resetForm }) => {
						sendFormDataToFirebaseDatabase(values, resetForm);
					}}
				>
					{(formik) => (
						<form
							onSubmit={formik.handleSubmit}
							className={`${
								successMessage ? " hidden" : "block"
							} mt-7 md:mt-14 p-7 bg-[#000f30] text-white`}
						>
							<TextInputField
								label="Name*"
								id="name"
								name="name"
								value={formik.values.name}
								handleChange={formik.handleChange}
							/>

							<div className="pt-5 flex flex-col md:flex-row gap-5 items-start">
								<SelectField
									label="What Service Do You Need?*"
									id="service"
									name="service"
									options={serviceOptions}
									value={formik.values.service}
									handleChange={formik.handleChange}
								/>
								<TextInputField
									label="Address*"
									name="address"
									id="address"
									value={formik.values.address}
									handleChange={formik.handleChange}
								/>
							</div>

							<div className="pt-5 flex flex-col md:flex-row gap-5">
								<TextInputField
									label="Phone*"
									name="phone"
									id="phone"
									value={formik.values.phone}
									handleChange={formik.handleChange}
								/>
								<TextInputField
									label="Email*"
									name="email"
									id="email"
									value={formik.values.email}
									handleChange={formik.handleChange}
								/>
							</div>

							<div className="pt-5 flex flex-col md:flex-row md:items-start gap-5">
								<div className="flex flex-col w-full relative">
									<label htmlFor="date" className="text-base 2xl:text-xl">
										Desired Date Of Service*
									</label>
									<i className="absolute right-2 top-[42px] text-gray-700 text-xl z-10">
										<MdCalendarMonth />
									</i>
									<DatePicker
										id="date"
										dateFormat="MM/dd/yyyy"
										selected={formik.values.date}
										minDate={new Date()}
										// we should use setFieldValue to set date field imperatively
										onChange={(date) => formik.setFieldValue("date", date)}
										className="w-full h-12 mt-1 pl-4 cursor-pointer text-base 2xl:text-xl text-primaryDark rounded-md border-2 border-gray-500 outline-none shadow-stone-200 shadow-md focus:border-none focus:ring-4 focus:ring-primary"
									/>
									{formik.touched.date && formik.errors.date ? (
										<div className="text-[red] text-base relative top-2">
											{formik.errors.date}
										</div>
									) : null}
								</div>

								<SelectField
									label="Time*"
									id="time"
									name="time"
									options={timeHours}
									value={formik.values.time}
									handleChange={formik.handleChange}
								/>
								<SelectField
									label="AM/PM*"
									id="timeMode"
									name="timeMode"
									options={timeMode}
									value={formik.values.timeMode}
									handleChange={formik.handleChange}
								/>
							</div>
							<div className="pt-5">
								<TextAreaField
									label="Additional Notes"
									id="message"
									name="message"
									value={formik.values.message}
									handleChange={formik.handleChange}
								/>
							</div>
							<button
								type="submit"
								className="mt-5 px-5 py-2 text-base 2xl:text-xl bg-white text-primaryDark rounded-md hover:bg-primaryDark hover:text-primary hover:shadow-sky-400 hover:shadow-inner hover:ring-2 hover:ring-primary"
							>
								Submit
							</button>
						</form>
					)}
				</Formik>

				{successMessage && (
					<div className="slide-in z-10 mt-6 relative bg-primaryDark p-4">
						<IoClose
							size="25px"
							className="absolute right-2 top-2 text-lightWhite cursor-pointer hover:text-primary active:text-primary"
							onClick={() => setSuccessMessage("")}
						/>
						<Paragraph text={successMessage} color="text-green-400" />
					</div>
				)}

				{errorMessage && (
					<div className="slide-in z-10 mt-6 relative -top-24 2xl:-top-28 bg-primaryDark p-4">
						<IoClose
							size="25px"
							className="absolute right-2 top-2 text-[red] cursor-pointer hover:text-lightWhite active:text-lightWhite"
							onClick={() => setErrorMessage(null)}
						/>
						<Paragraph text={errorMessage} color="text-[red]" />
					</div>
				)}
			</div>
		</>
	);
};

export default Contacts;
