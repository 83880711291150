import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Paragraph from "../components/common/Paragraph";
import { jobOpenings } from "../data/jobOpenings";
import HeroImgTextOverlay from "../components/HeroImgTextOverlay";

const Careers = () => {
	return (
		<>
			{/* implement react-helmet-async */}
			<Helmet>
				<title>Careers - ONS Services</title>
				<meta
					name="description"
					content="Join our team at ONS Services and be part of our mission to provide exceptional hotel cleaning and hospitality services."
				/>
				<meta
					name="keywords"
					content="careers, job opportunities, hotel cleaning, hospitality services"
				/>
				<link rel="canonical" href="https://ons.ae/carrers" />

				{/* facebook meta tags */}
				<meta property="og:title" content="Careers - ONS Services" />
				<meta
					property="og:description"
					content="Join our team at ONS Services and be part of our mission to provide exceptional hotel cleaning and hospitality services."
				/>
				<meta property="og:site_name" content="ONS Services" />
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://ons.ae/carrers" />
				<meta
					property="og:image"
					content="https://ons.ae/images/carrers/carrers-thumbnail.png"
				/>
				<meta property="og:image:alt" content="Careers - ONS Services" />

				{/* Twitter meta tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="Careers - ONS Services" />
				<meta
					name="twitter:description"
					content="Join our team at ONS Services and be part of our mission to provide exceptional hotel cleaning and hospitality services."
				/>
				<meta
					name="twitter:image"
					content="https://ons.ae/images/carrers/carrers-thumbnail.png"
				/>
			</Helmet>

			<HeroImgTextOverlay
				img="/images/carrers/heroimg.webp"
				heading="Join Our Team"
				text="Unleash your potential | Transform hospitality"
			/>

			<section className="max-w-6xl mx-auto px-4 xl:px-0 mt-7 md:mt-14">
				<h2 className="text-primaryDark text-2xl sm:text-4xl font-semibold sm:font-bold">
					Careers at ONS Services
				</h2>
				<div className="pt-2">
					<Paragraph
						text="Thank you for your interest in joining the ONS Services team. We
						are committed to providing exceptional hotel cleaning and
						hospitality services to our clients. If you are passionate about
						delivering top-notch service and creating memorable experiences, we
						would love to hear from you."
					/>
					<div className="py-2">
						<Paragraph
							text="Please browse our current job openings below and submit your
						application through the provided links. We look forward to reviewing
						your qualifications."
						/>
					</div>
				</div>
				<div className="mt-6 px-5 pt-8 pb-5 bg-primaryDark text-white rounded-lg">
					<h3 className="text-primary text-xl sm:text-3xl text-center font-semibold mb-2">
						Current Openings
					</h3>
					<table className="min-w-full mt-10 table-auto border-collapse">
						<thead>
							<tr>
								<th className="text-left pb-4 text-lg">Designation</th>
								<th className="text-left pb-4 text-lg">Openings</th>
								<th className="hidden md:table-cell text-left pb-4 text-lg">
									Deadline
								</th>
								<th className="text-left pb-4 text-lg"></th>
							</tr>
						</thead>
						<tbody>
							{jobOpenings.map((job) => (
								<React.Fragment key={uuidv4()}>
									<tr>
										<td className="text-left border-y border-primary py-3">
											{job.designation}
										</td>
										<td className="text-left border-y border-primary py-3 pl-6">
											{job.openings}
										</td>
										<td className="hidden md:table-cell text-left border-y border-primary py-3">
											{job.deadline}
										</td>
										<td className="text-left border-y border-primary py-3">
											<Link
												to={`/carrers/${job.designation
													.toLowerCase()
													.replace(/\s/g, "-")}`}
												className="text-primary hover:underline"
											>
												View More
											</Link>
										</td>
									</tr>
								</React.Fragment>
							))}
						</tbody>
					</table>
				</div>
			</section>
		</>
	);
};

export default Careers;
