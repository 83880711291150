import React from "react";
import Paragraph from "./Paragraph";

const TextBlock = ({ heading, text }) => {
	return (
		<div>
			<h3 className="mt-8 text-primaryDark text-xl 2xl:text-2xl font-bold">
				{heading}
			</h3>
			<Paragraph text={text} />
		</div>
	);
};

export default TextBlock;
