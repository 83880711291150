import React from "react";
import { ImQuotesLeft } from "react-icons/im";

const TestimonialCard = ({ experience, img, name, date }) => {
	return (
		<>
			<div className="pt-5 sm:pt-10 flex flex-col items-center justify-center">
				<ImQuotesLeft size="40px" fill="#000f30" />
				<p className="text-center px-14 text-base 2xl:text-xl py-8">
					{experience}
				</p>
				<div className="flex items-start gap-x-4">
					<img
						src={img}
						alt="customer"
						className="w-14 h-14 object-contain rounded-full"
					/>
					<div className="mb-10">
						<h3 className="text-xl 2xl:text-2xl font-bold text-primaryDark">
							{name}
						</h3>
						<p className="text-base 2xl:text-xl text-primaryDark text-end font-medium">
							{date}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default TestimonialCard;
