import React from "react";
import { NavLink } from "react-router-dom";

const NavigationMenu = ({
	icon,
	path,
	pageMatch,
	pageTitle,
	toggleHamburgerMenu,
	setToggleHamburgerMenu,
}) => {
	return (
		<li className="flex items-center gap-x-10">
			<i
				className={`text-3xl ${pageMatch ? `text-primary` : `text-lightWhite`}`}
			>
				{icon}
			</i>
			<NavLink
				to={path}
				className={`${
					pageMatch ? "text-primary" : "text-lightWhite"
				} hover:text-primary hover:border-b-2 hover:border-primary`}
				onClick={() => setToggleHamburgerMenu(!toggleHamburgerMenu)}
			>
				{pageTitle}
			</NavLink>
		</li>
	);
};

export default NavigationMenu;
