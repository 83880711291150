import React from "react";

const SocialMediaLinks = ({ url, icon }) => {
	return (
		<a href={url}>
			<i className="text-xl">{icon}</i>
		</a>
	);
};

export default SocialMediaLinks;
