export const infocards = [
	{
		img: "./images/info-section/about-us.png",
		title: "About Us",
		desc: "ONS Facility Services, a member of Metro Global, is a one-stop shop for all your cleaning and maintenance needs. Our personalised business offering enables us to develop genuine relationships with each client.",
		url: "/about",
		urlText: "Read more",
	},
	{
		img: "./images/info-section/our-mission-values.png",
		title: "Our Mission and Values",
		desc: "ONS Facility Services is dedicated to providing the hospitality industry with the cleanest accommodation, a safe work environment for all employees and to continuously provide a value-for-money service to their clients.",
		url: "#",
		urlText: "Read more",
	},
	{
		img: "./images/info-section/our-policy.png",
		title: "Quality Policy",
		desc: "At ONS Facility Services, we recognise that our current and future success relies largely on customer satisfaction. Various specialised services offered are completed by individuals who have received specialised training.",
		url: "#",
		urlText: "Read more",
	},
	{
		img: "./images/info-section/our-services.png",
		title: "Our Services",
		desc: "ONS Facility Services specializes in housekeeping and hospitality solutions. With our commitment to quality, professionalism, and customer satisfaction, we strive to exceed your expectations.",
		url: "#",
		urlText: "Read more",
	},
];
