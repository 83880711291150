import React from "react";

const IconWithName = ({ icon, name }) => {
	return (
		<div className="py-1 text-sm flex items-center gap-x-3">
			{icon}
			{name}
		</div>
	);
};

export default IconWithName;
