import React from "react";
import { v4 as uuidv4 } from "uuid";
import { servicebenefits } from "../data/servicebenefits";
import ServiceBenefit from "./common/ServiceBenefit ";

const WhyChooseUsSection = () => {
	return (
		<section className="max-w-6xl mx-auto px-4 xl:px-0 pt-7 md:pt-14">
			<h2 className="text-primaryDark text-3xl sm:text-4xl font-semibold sm:font-bold">
				Why Choose Our Hotel Cleaning Services
			</h2>
			<div className="pt-8 grid md:grid-cols-2 gap-x-4 gap-y-8">
				{servicebenefits.map((service) => (
					<ServiceBenefit
						key={uuidv4()}
						title={service.title}
						desc={service.desc}
					/>
				))}
			</div>
		</section>
	);
};

export default WhyChooseUsSection;
