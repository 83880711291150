import { useField } from "formik";
import React from "react";

const TextInputField = ({ label, handleChange, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<div className="w-full">
			<label htmlFor={props.id || props.name} className="text-base 2xl:text-xl">
				{label}
			</label>
			<input
				onChange={handleChange}
				type="text"
				{...field}
				{...props}
				className={`${
					meta.touched && meta.error ? `border-2 border-[red]` : ``
				} w-full h-12 mt-1 pl-4 text-base 2xl:text-xl text-primaryDark  rounded-md border-2 border-gray-500 outline-none shadow-stone-200 shadow-md focus:border-none focus:ring-4 focus:ring-primary`}
			/>
			{meta.touched && meta.error ? (
				<div className="text-[red] text-base relative top-2">{meta.error}</div>
			) : null}
		</div>
	);
};

export default TextInputField;
