export const sliderContentsHomePage = [
	{
		img: "./images/slide1.jpg",
		title: "our current partner hotels",
		lists: [
			"presently servicing 30 hotels in uae.",
			"10 serviced apartments in uae",
			"10 offices and residence complexes in uae",
		],
	},
	{
		img: "./images/slide3.jpg",
		title: "our core business",
		lists: [
			"hospitality services",
			"procurement services",
			"asset management",
			"facility management",
		],
	},
	{
		img: "./images/slide2.jpg",
		title: "we offer",
		lists: [
			"FULL SOLUTION SERVICES WITH STRESS FREE, COST EFFECTIVE AND ATTRACTIVE TO THE CLIENTS. ",
			"VARIETY OF PRODUCTS AND SERVICES THAT SUITE ALL HOTEL NEEDS , STARTING FROM THE PRE-OPENING PROCUREMENT. ",
		],
	},
];

export const sliderContentsAboutPage = [
	{
		img: "./images/about-us/slider-1.webp",
		title: "Experience Professional",
		lists: [
			"Thorough cleaning of rooms and areas",
			"High-quality standards for satisfaction",
			"Trained, reliable staff for efficiency",
		],
	},
	{
		img: "./images/about-us/slider-2.webp",
		title: "Our Commitment",
		lists: [
			"Strict protocols and industry standards",
			"Eco-friendly, safe cleaning products",
			"Regular sanitization for a healthy environment",
		],
	},
	{
		img: "./images/about-us/slider-3.webp",
		title: "Exceptional Service",
		lists: [
			"Prompt response to guest requests",
			"Attention to detail for a pristine atmosphere",
			"Personalized cleaning plans for hotels",
		],
	},
];
