import React from "react";
import { IoClose } from "react-icons/io5";

const Modal = ({ setIsModalVisibile, mainText, inlineText }) => {
	return (
		<div className="h-screen w-full fixed top-0 left-0  z-10 flex items-center justify-center">
			<div className="bg-[#020a1cd9] text-white p-4">
				<button
					onClick={() => setIsModalVisibile(false)}
					className="inline-flex float-right relative -top-3 -right-3"
				>
					<IoClose
						size="20px"
						className="text-lightWhite hover:text-[rgb(234,88,12)] hover:scale-110 active:text-[rgb(234,88,12)]"
					/>
				</button>

				<p className="text-base text-white font-normal sm:inline-block">
					{mainText}{" "}
					<span className="text-base text-orange-600 font-bold">
						{inlineText}
					</span>
				</p>
			</div>
		</div>
	);
};

export default Modal;
