export const timeMode = [
	{ value: "choose", label: "Choose" },
	{ value: "am", label: "AM" },
	{ value: "pm", label: "PM" },
];

export const timeHours = [{ value: "choose", label: "Choose" }];

for (let hour = 1; hour <= 12; hour++) {
	const hourValue = hour.toString().padStart(2, "0");

	timeHours.push({
		value: hourValue.concat(":00"), // concatenate string with concat method
		label: hourValue + ":00", // concatenate string with unary plus
	});

	timeHours.push({ value: `${hourValue}:30`, label: `${hourValue}:30` });
}

export const serviceOptions = [
	{
		value: "choose",
		label: "Choose Services",
	},
	{
		value: "room-cleaning",
		label: "Room Cleaning",
	},
	{
		value: "carpet-steam-cleaning",
		label: "Carpet Steam Cleaning",
	},
	{
		value: "upholstery-cleaning",
		label: "Upholstery Cleaning",
	},
	{
		value: "high-pressure-steam-cleaning",
		label: "High Pressure and Steam Cleaning",
	},
	{
		value: "post-construction-refurbishment",
		label: "Post Construction or Refurbishment Cleaning",
	},
	{
		value: "housekeeping-supplies",
		label: "Houseeeping Supplies",
	},
	{
		value: "cleaning-supplies",
		label: "cleaning Supplies",
	},
	{
		value: "spacare-sanitation",
		label: "Spacare Sanitation",
	},
	{
		value: "pest-control",
		label: "Pest Control",
	},
	{
		value: "furniture-fittings",
		label: "Furniture Fittings",
	},
	{
		value: "public-area-cleaning",
		label: "Public Area Cleaning",
	},
	{
		value: "amenities",
		label: "Amenities",
	},

	{
		value: "commercial-cleaning",
		label: "Commercial Cleaning",
	},
	{
		value: "floor-care",
		label: "Floor Care",
	},
];
