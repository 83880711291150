import React from "react";

const HeroImgTextOverlay = ({ img, heading, text }) => {
	return (
		<section>
			<img
				src={img}
				alt="carrers_picture/careers-hero.webp"
				className="w-full h-[600px] brightness-50 object-cover bg-primaryDark"
			/>
			<div className="text-white absolute top-72 left-[6%]">
				<h1 className="uppercase text-3xl sm:text-5xl 2xl:text-6xl font-bold text-white">
					{heading}
				</h1>
				<p className="pt-2 uppercase text-2xl 2xl:text-3xl font-semibold">
					{text}
				</p>
			</div>
		</section>
	);
};

export default HeroImgTextOverlay;
