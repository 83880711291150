import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";

export const contactsData = [
	{
		icon: <MdLocationPin />,
		heading: "Address",
		details: [
			"1301 Swiss Tower, Jumeriah Lake Towers",
			"Dubai, PO BOX :214361",
			"United Arab Emirates",
		],
	},
	{
		icon: <FaPhone />,
		heading: "Phone",
		details: ["+971 23456789"],
	},
	{
		icon: <IoMdMail />,
		heading: "Email",
		details: ["support@onsfacilityservices.uae"],
	},
];
