import React, { useState } from "react";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";

const Accordian = ({ title, content }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleAccordian = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="mb-4">
			<div
				className={`${
					isOpen ? "bg-primaryDark text-primary" : ""
				} p-4 flex items-start gap-x-5 bg-lightWhite cursor-pointer transition duration-700 ease-in-out`}
				onClick={toggleAccordian}
			>
				{isOpen ? (
					<FaCaretRight size="25px" className="animate-pulse" />
				) : (
					<FaCaretDown size="25px" />
				)}

				<h3
					className={`${
						isOpen ? "text-primary" : "text-primaryDark"
					} text-lg font-semibold transition duration-500 ease-in-out`}
				>
					{title}
				</h3>
			</div>

			<div
				className={`${
					isOpen ? "visible opacity-100 h-min pb-4" : "collapse opacity-0 h-0"
				} bg-primaryDark text-white transition duration-1000 ease-in-out`}
			>
				<p className="ml-8 py-1 px-8 pr-4 border-dotted border-l-2 border-primary !text-base 2xl:text-xl">
					{content}
				</p>
			</div>
		</div>
	);
};

export default Accordian;
