import React from "react";
import { Link } from "react-router-dom";
import Accordian from "./common/Accordian";
import { accordiansdata } from "../data/accordiansData";
import { v4 as uuidv4 } from "uuid";

const FrequentlyAskedQuestions = () => {
	return (
		<section className="max-w-6xl mx-auto px-4 xl:px-0 pt-7 md:pt-14">
			<h2 className="text-primaryDark text-3xl sm:text-4xl font-semibold sm:font-bold">
				Frequently Asked Questions
			</h2>
			<p className="text-base 2xl:text-xl py-5">
				Got a question about hotel cleaning services? We’re here to answer it.
				If you don’t see your question here, drop us a line on our{" "}
				<span className="text-primaryDark font-semibold">
					<Link to="/contacts">Contact Us</Link>
				</span>{" "}
				page.
			</p>
			{accordiansdata.map((data) => (
				<Accordian key={uuidv4()} title={data.title} content={data.content} />
			))}
		</section>
	);
};

export default FrequentlyAskedQuestions;
