import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { JobDescriptionCard } from "../components/JobDescriptionCard";
import Paragraph from "../components/common/Paragraph";
import { jobOpenings } from "../data/jobOpenings";
import Modal from "../components/common/Modal";
import HeroImgTextOverlay from "../components/HeroImgTextOverlay";

const JobDetails = () => {
	const [isModalVisibile, setIsModalVisibile] = useState(false);
	const { designation } = useParams();
	const jobTitle = designation.toString().replace(/-/g, " ");

	const indexOfJobDetailsInJobOpenings = jobOpenings.indexOf(
		jobOpenings.find(
			(element) => element.designation.toLowerCase() === jobTitle
		)
	);

	const handleJobApply = () => {
		setIsModalVisibile(true);
	};
	return (
		<div>
			{/* hero section */}
			<HeroImgTextOverlay
				img="/images/job-details/careers-hero.webp"
				heading={jobOpenings[indexOfJobDetailsInJobOpenings].designation}
				text={jobOpenings[indexOfJobDetailsInJobOpenings].location}
			/>

			<section className="max-w-6xl mx-auto px-4 xl:px-0 pt-7 md:pt-14 flex flex-col lg:flex-row items-start justify-between gap-5">
				{/* card */}
				<main className="w-full">
					<div className="w-full bg-primaryDark text-white px-6 py-10 rounded-md">
						<p className="py-1 text-base 2xl:text-xl">
							Openings:{" "}
							<span className="block sm:inline-block sm:pl-[119px] text-primary font-medium">
								{jobOpenings[indexOfJobDetailsInJobOpenings].openings}
							</span>
						</p>
						<p className="py-1 text-base 2xl:text-xl">
							Posted On:{" "}
							<span className="block sm:inline-block sm:pl-28 text-lime-500 font-medium">
								{jobOpenings[indexOfJobDetailsInJobOpenings].postedOn}
							</span>
						</p>
						<p className="py-1 text-base 2xl:text-xl">
							Deadline:{" "}
							<span className="block sm:inline-block sm:pl-[123px] text-[red] font-medium">
								{jobOpenings[indexOfJobDetailsInJobOpenings].deadline}
							</span>
						</p>
						<p className="py-1 text-base 2xl:text-xl">
							Designation:{" "}
							<span className="block sm:inline-block sm:pl-[97px] text-lime-500 font-medium">
								{jobOpenings[indexOfJobDetailsInJobOpenings].designation}
							</span>
						</p>
					</div>

					<div className="w-full mt-6 bg-primaryDark text-white px-6 py-10 rounded-md">
						{/* job desc */}
						<JobDescriptionCard
							heading="Description"
							iterable={jobOpenings[indexOfJobDetailsInJobOpenings].desc}
						/>

						<div className="mt-6">
							{/* job requirements */}
							<JobDescriptionCard
								heading="Requirements"
								iterable={
									jobOpenings[indexOfJobDetailsInJobOpenings].requirements
								}
							/>
						</div>

						<div className="mt-6">
							{/* job qualifications & experiences */}
							<JobDescriptionCard
								heading="Qualifications & Experiences"
								iterable={
									jobOpenings[indexOfJobDetailsInJobOpenings].qualifications
								}
							/>
						</div>

						{/* apply now */}
						<button
							className="mt-5 px-5 py-2 text-base 2xl:text-xl bg-white text-primaryDark rounded-md hover:bg-primaryDark hover:text-primary hover:shadow-sky-400 hover:shadow-inner hover:ring-2 hover:ring-primary"
							onClick={handleJobApply}
						>
							Apply
						</button>
						{isModalVisibile && (
							<Modal
								setIsModalVisibile={setIsModalVisibile}
								mainText="Interested candidates are requested to submit their mail at"
								inlineText="carrer@ons.ae"
							/>
						)}
					</div>
				</main>

				{/* other job openings */}
				<aside className="w-full lg:max-w-lg bg-primaryDark text-white px-4 py-8 rounded-md">
					<h3 className="text-primary text-lg md:text-2xl font-semibold">
						Other Openings
					</h3>
					{jobOpenings
						.filter((detail, idx) => idx !== indexOfJobDetailsInJobOpenings)
						.map((job) => (
							<div className="pt-6" key={uuidv4()}>
								<p className="text-[red] font-medium">
									Deadline: {job.deadline}
								</p>
								<Link
									to={`/carrers/${job.designation
										.toLowerCase()
										.replace(/\s/g, "-")}`}
								>
									<p className="py-1.5 text-lime-500 font-medium hover:text-primary hover:underline">
										{job.designation}
									</p>
								</Link>
								<p className="text-sm text-lightWhite">{job.desc[0]}</p>
							</div>
						))}
					<hr className="my-6 border-primary" />
					<Paragraph
						text="Apply for the job that you are eligible for or directly reach out to us at"
						color="text-lightWhite"
					/>
					<div className="pt-2">
						<Paragraph text="career@onsservices.com" color="text-green-400" />
					</div>
				</aside>
			</section>
		</div>
	);
};

export default JobDetails;
