export const privacyPolicy = [
	{
		heading: "Information Collection",
		text: "We may collect personal information from customers, including but not limited to name, address, email, phone number, and payment information. We collect this information when you use our services or when you contact us through our website or phone.",
	},
	{
		heading: "Use of Information",
		text: "We may use the personal information collected to provide our hospitality and housekeeping services to you, process payments, communicate with you about your service requests, improve our services, personalize your experience with us, and for marketing purposes.",
	},
	{
		heading: "Disclosure of Information",
		text: "We may disclose personal information to trusted third-party service providers who assist us in providing our hospitality and housekeeping services to you. We may also disclose personal information if required by law or if we believe that disclosure is necessary to protect our rights or the safety of our customers.",
	},
	{
		heading: "Security",
		text: "We take reasonable measures to protect personal information from unauthorized access, use, and disclosure. We use industry-standard security measures, including secure servers, firewalls, andencryption, to safeguard your personal information.",
	},
	{
		heading: "Changes to Privacy Policy",
		text: "We reserve the right to update or modify this privacy policy at any time. Any changes made will be effective immediately upon posting to our website.",
	},
	{
		heading: "Contact Us",
		text: "If you have any questions or concerns about our privacy policy or the way we handle personal information, please contact us at support@onsfacilityservices.uae",
	},
];
