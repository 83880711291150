import React from "react";
import { v4 as uuidv4 } from "uuid";
import { privacyPolicy } from "../data/privacyPolicy";
import Paragraph from "./common/Paragraph";
import TextBlock from "./common/TextBlock";

const PrivacyPolicy = () => {
	return (
		<section className="max-w-6xl mx-auto px-4 pb-7 pt-7 mt-7 md:pt-14 md:mt-14 bg-lightWhite text-black">
			<h2 className="text-PrimaryDark text-3xl sm:text-4xl font-semibold sm:font-bold">
				Privacy Policy
			</h2>
			<div className="pt-2 text-base text-gray-700">
				<Paragraph
					text="At ONS Solutions, we are committed to protecting the privacy of our
					customers and visitors. This privacy policy outlines how we collect,
					use, and disclose personal information provided to us through our
					website, mobile applications, and in-person interactions."
				/>
				{privacyPolicy.map((data) => (
					<TextBlock key={uuidv4()} heading={data.heading} text={data.text} />
				))}
			</div>
		</section>
	);
};

export default PrivacyPolicy;
