import React from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";
import NavigationMenu from "./common/NavigationMenu";

const HamburgerMenu = ({
	toggleHamburgerMenu,
	setToggleHamburgerMenu,
	navigationsData,
}) => {
	return (
		<>
			<button
				onClick={() => setToggleHamburgerMenu(!toggleHamburgerMenu)}
				className="block md:hidden"
			>
				{toggleHamburgerMenu && (
					<IoMenu
						size="30px"
						className="text-lightWhite hover:text-primary active:text-primary"
					/>
				)}
			</button>
			{/* hamburger menu for mobile devices */}
			{!toggleHamburgerMenu && (
				<div className="block md:hidden w-full h-screen py-10 fixed top-0 left-0  bg-primaryDark flex items-center justify-center">
					<ul className="flex gap-y-10 flex-col justify-center uppercase text-lg 2xl:text-xl font-normal">
						<button
							onClick={() => setToggleHamburgerMenu(!toggleHamburgerMenu)}
						>
							<IoClose
								size="30px"
								className="fixed top-12 right-4 text-lightWhite hover:text-primary active:text-primary"
							/>
						</button>
						{navigationsData.map((navigation) => (
							<NavigationMenu
								key={uuidv4()}
								icon={navigation.icon}
								path={navigation.path}
								pageMatch={navigation.pageMatch}
								pageTitle={navigation.pageTitle}
								toggleHamburgerMenu={toggleHamburgerMenu}
								setToggleHamburgerMenu={setToggleHamburgerMenu}
							/>
						))}
					</ul>
				</div>
			)}
		</>
	);
};

export default HamburgerMenu;
