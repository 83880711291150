export const ourValues = [
	{
		title: "Customer Satisfaction:",
		desc: "We prioritize the satisfaction of our hotel clients by delivering top-notch cleaning services tailored to their specific needs.",
	},
	{
		title: "Excellence:",
		desc: "We strive for excellence in every aspect of our work, paying attention to detail and maintaining the highest quality standards.",
	},
	{
		title: "Reliability:",
		desc: "We are committed to being a dependable partner, consistently delivering reliable and timely cleaning services.",
	},
	{
		title: "Professionalism:",
		desc: "Our team of dedicated cleaning professionals upholds the utmost professionalism and respect while serving our clients.",
	},
	{
		title: "Sustainability:",
		desc: "We prioritize environmentally friendly practices by using eco-friendly cleaning products and promoting sustainability in our operations.",
	},
];
