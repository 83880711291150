import React from "react";
import Paragraph from "./Paragraph";

const ServiceCard = ({ img, altImg, heading, text }) => {
	return (
		<div className="relative">
			<img
				src={img}
				alt={altImg}
				className="w-full h-44 lg:h-52 brightness-[0.4] object-cover bg-primaryDark"
			/>
			<div className="absolute left-5 top-5 brightness-100 opacity-100 text-white">
				<h3 className="text-xl sm:text-2xl font-semibold">{heading}</h3>
				<div className="py-5">
					<Paragraph text={text} />
				</div>
			</div>
		</div>
	);
};

export default ServiceCard;
