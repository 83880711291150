import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { v4 as uuidv4 } from "uuid";
import { testimonials } from "../data/testimonials";
import TestimonialCard from "./common/TestimonialCard";
import "../styles/swipercarousel.css";

const ClientTestimonials = () => {
	return (
		<section className="max-w-6xl mx-auto px-4 xl:px-0 pt-7 md:pt-14">
			<h2 className="text-primaryDark text-3xl sm:text-4xl font-semibold sm:font-bold">
				Client Testimonials
			</h2>
			<Swiper
				slidesPerView={1}
				loop={true}
				autoplay={{
					delay: 15000,
					disableOnInteraction: true,
				}}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
			>
				{testimonials.map((testimonial) => (
					<SwiperSlide key={uuidv4()}>
						<TestimonialCard
							name={testimonial.name}
							img={testimonial.img}
							date={testimonial.date}
							experience={testimonial.experience}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};

export default ClientTestimonials;
