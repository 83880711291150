import React from "react";
import InfoCard from "./common/InfoCard";
import { infocards } from "../data/infocards";
import { v4 as uuidv4 } from "uuid";

const InfoSection = () => {
	return (
		<section className="max-w-6xl mx-auto px-4 xl:px-0 pt-7 md:pt-14">
			<div className="flex gap-4 flex-wrap lg:flex-nowrap justify-center">
				{infocards.map((card) => (
					<InfoCard
						key={uuidv4()}
						img={card.img}
						title={card.title}
						desc={card.desc}
						url={card.url}
						urlText={card.urlText}
					/>
				))}
			</div>
		</section>
	);
};

export default InfoSection;
