import React from "react";
import { v4 as uuidv4 } from "uuid";
import Paragraph from "../components/common/Paragraph";
import ServiceCard from "../components/common/ServiceCard";
import { ourServices } from "../data/ourServices";
import { Helmet } from "react-helmet-async";
import HeroImgTextOverlay from "../components/HeroImgTextOverlay";

const Services = () => {
	return (
		<>
			<Helmet>
				<title>Our Services - ONS Solutions</title>
				<meta
					name="description"
					content="Explore our comprehensive range of services offered by ONS Solutions. From room cleaning to pest control, we provide meticulous maintenance and ensure a memorable guest experience."
				/>
				<meta
					name="keywords"
					content="ONS Solutions services, hotel cleaning services, housekeeping solutions, janitorial services, floor care, carpet cleaning, window cleaning, guest room cleaning"
				/>
				<link rel="canonical" href="https://onssolution.netlify.app/services" />

				{/* Facebook Meta Tags */}
				<meta property="og:title" content="Our Services - ONS Solutions" />
				<meta
					property="og:description"
					content="Explore our comprehensive range of services offered by ONS Solutions. From room cleaning to pest control, we provide meticulous maintenance and ensure a memorable guest experience."
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://onssolution.netlify.app/services-thumbnail.png"
				/>
				<meta
					property="og:image"
					content="https://onssolution.netlify.app/services-thumbnail.png"
				/>

				{/* Twitter Meta Tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="Our Services - ONS Solutions" />
				<meta
					name="twitter:description"
					content="Explore our comprehensive range of services offered by ONS Solutions. From room cleaning to pest control, we provide meticulous maintenance and ensure a memorable guest experience."
				/>
				<meta
					name="twitter:image"
					content="https://onssolution.netlify.app/services-thumbnail.png"
				/>
			</Helmet>

			<HeroImgTextOverlay
				img="/images/our-services/heroimg.webp"
				heading="Exceptional cleaning services"
				text="Unforgettable hospitality | Unmatched excellence"
			/>

			<section className="max-w-6xl mx-auto px-4 xl:px-0 mt-7 md:mt-14">
				<h2 className="text-primaryDark text-3xl sm:text-4xl font-semibold sm:font-bold">
					Our Services
				</h2>
				<div className="pt-2">
					<Paragraph text="When you partner with ONS Solutions, you get a dedicated team of professionals committed to delivering exceptional services. From room cleaning to pest control, trust us for meticulous maintenance and five-star guest experiences" />
				</div>
				<div className="mt-5 grid grid-cols-1 sm:grid-cols-2 gap-5">
					{ourServices.map((service) => (
						<ServiceCard
							key={uuidv4()}
							img={service.img}
							altImg={service.heading}
							heading={service.heading}
							text={service.text}
						/>
					))}
				</div>
			</section>
		</>
	);
};

export default Services;
