import { AiFillSkype } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { FaFacebook, FaLinkedin } from "react-icons/fa";

export const socialmedialinks = [
	{
		url: "https://www.facebook.com/",
		title: "facebook",
		icon: <FaFacebook />,
	},
	{
		url: "https://www.linkedin.com/in/",
		title: "Linkedin",
		icon: <FaLinkedin />,
	},
	{ url: "https://www.skype.com/", title: "Skype", icon: <AiFillSkype /> },
	{ url: "https://www.twitter.com/", title: "Twitter", icon: <BsTwitter /> },
];
