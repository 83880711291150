import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ourValues } from "../data/ourvalues";

const OurMissionAndValues = () => {
	return (
		<section
			className="max-w-6xl mx-auto px-4 pt-7 mt-7 md:pt-14 md:mt-14 bg-primaryDark text-white"
			id="/about/mission"
		>
			<h2 className="text-lightWhite text-3xl sm:text-4xl font-semibold sm:font-bold">
				Our Mission and Values
			</h2>
			<div className="py-7 flex flex-col sm:flex-row gap-5">
				<div className="w-full md:w-1/2">
					<h3 className="text-primary text-xl sm:text-2xl font-semibold sm:font-bold">
						Mission
					</h3>
					<p className="py-2 text-base 2xl:text-xl">
						Our mission is to provide exceptional cleaning services to hotels,
						ensuring a clean and comfortable environment for guests. We strive
						to exceed expectations by delivering professional and reliable
						cleaning solutions that meet the highest standards of cleanliness
						and hygiene.
					</p>
				</div>
				<div className="w-full md:w-1/2 sm:pl-5 ">
					<h3 className="text-primary text-xl sm:text-2xl font-semibold sm:font-bold">
						Values
					</h3>
					<ul className="list-disc text-base 2xl:text-xl text-primary">
						{ourValues.map((value) => (
							<li key={uuidv4()} className="py-2">
								<span className="text-primary font-bold">{value.title}</span>{" "}
								<span className="text-base 2xl:text-xl text-white">
									{value.desc}
								</span>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default OurMissionAndValues;
