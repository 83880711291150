import React from "react";
import { useRouteError, Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/layouts/Footer";

export default function ErrorPage() {
	const error = useRouteError();
	console.error(error);

	return (
		<>
			<Navbar />
			<div className="mt-[150px] py-10 flex items-center justify-center bg-blend-lighten bg-primaryDark">
				<div className="text-white font-semibold text-center">
					<h1 className="text-6xl text-center">404</h1>
					<p className="text-2xl mb-4">
						Page not found
						<span role="img" aria-label="confused face emoji">
							😕
						</span>
					</p>
					<Link to="/" className="text-xl font-medium underline text-primary">
						Back to home page
					</Link>
				</div>
			</div>
			<Footer />
		</>
	);
}
