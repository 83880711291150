export const whoWeServe = [
	{
		title: "Hotels and Resorts",
		desc: "Pristine cleaning services for hotels and resorts of all sizes, ensuring a welcoming environment for guests.",
	},
	{
		title: "Bed and Breakfasts",
		desc: "Tailored housekeeping services for cozy bed and breakfast establishments, ensuring a comfortable and enjoyable stay for guests.",
	},
	{
		title: "Vacation Rentals",
		desc: "Reliable cleaning services for vacation rentals, maintaining cleanliness and hygiene between guest stays for a delightful experience.",
	},
	{
		title: "Corporate Offices:",
		desc: "Professional cleaning services for corporate offices, creating an organized and hygienic workspace for enhanced productivity.",
	},
	{
		title: "Event Venues:",
		desc: "Meticulous cleaning services for event venues, ensuring a clean and inviting atmosphere for successful gatherings and memorable experiences.",
	},
	{
		title: "Restaurants and Cafes:",
		desc: "Thorough cleaning services for restaurants and cafes, maintaining cleanliness in dining areas and kitchens for an enjoyable dining experience.",
	},
	{
		title: "Medical Facilities:",
		desc: "Specialized cleaning services for medical facilities, ensuring a germ-free and hygienic environment for the well-being of patients and staff.",
	},
	{
		title: "Educational Institutions:",
		desc: "Comprehensive cleaning services for schools and universities, providing a clean and healthy learning environment for students and faculty.",
	},
];

export const whyChooseONS = [
	{
		title: " Professional Expertise",
		desc: "Years of experience in hospitality and housekeeping services. Exceptional and tailored solutions for your needs.",
	},
	{
		title: "Comprehensive Services",
		desc: "Covering all aspects of hospitality. From regular cleaning to specialized tasks, we take care of every detail.",
	},
	{
		title: "Quality Assurance",
		desc: "Strict protocols to ensure highest standards. Your satisfaction is our top priority.",
	},
	{
		title: "Reliable and Trustworthy",
		desc: "Dependable, punctual, and dedicated to delivering exceptional services consistently.",
	},
	{
		title: "Flexible Scheduling",
		desc: "Accommodating your schedule with flexibility to meet your specific requirements.",
	},
	{
		title: "Customer Satisfaction",
		desc: "Committed to providing excellent service and exceeding customer expectations.",
	},
	{
		title: "Cost-Effective Solutions",
		desc: "Delivering high-quality services at competitive prices to optimize your budget.",
	},
	{
		title: "Attention to Detail",
		desc: "Meticulous approach to every task, ensuring no detail is overlooked.",
	},
];
