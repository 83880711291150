import React from "react";
import HeroImageCarousel from "../components/HeroImageCarousel";
import OurMissionAndValues from "../components/OurMissionAndValues";
import WhoWeAre from "../components/WhoWeAre";
import { sliderContentsAboutPage } from "../data/herosectionsData";
import FeatureSlider from "../components/FeatureSlider";
import { whoWeServe, whyChooseONS } from "../data/featureSlider";
import PrivacyPolicy from "../components/PrivacyPolicy";
import { Helmet } from "react-helmet-async";

const About = () => {
	return (
		<>
			{/* implement react-helmet-async */}
			<Helmet>
				<title>About Us - ONS Solutions</title>
				<meta
					name="description"
					content="Learn about our company and our commitment to providing professional hotel cleaning and hospitality services."
				/>
				<meta
					name="keywords"
					content="hotel cleaning, hospitality services, ONS Solutions, company, mission, values, who we are, who we serve, why choose ONS"
				/>
				<link rel="canonical" href="https://onssolution.netlify.app/about" />

				{/* facebook meta tags */}
				<meta property="og:title" content="About Us - ONS Solutions" />
				<meta
					property="og:description"
					content="Learn about our company and our commitment to providing professional hotel cleaning and hospitality services."
				/>
				<meta property="og:site_name" content="ONS Solution" />
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://onssolution.netlify.app/about"
				/>
				<meta
					property="og:image"
					content="https://onssolution.netlify.app/images/about-thumbnail.png"
				/>
				<meta property="og:image:alt" content="About Us - ONS Solutions" />

				{/* twitter meta tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="About Us - ONS Solutions" />
				<meta
					name="twitter:description"
					content="Learn about our company and our commitment to providing professional hotel cleaning and hospitality services."
				/>
				<meta
					name="twitter:image"
					content="https://onssolution.netlify.app/images/about-thumbnail.png"
				/>
			</Helmet>

			<HeroImageCarousel sliderContents={sliderContentsAboutPage} />
			<OurMissionAndValues />
			<WhoWeAre />
			<FeatureSlider heading="Who We Serve" data={whoWeServe} />
			<FeatureSlider heading="Why Choose ONS" data={whyChooseONS} />
			<PrivacyPolicy />
		</>
	);
};

export default About;
