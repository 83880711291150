import React from "react";
import ClientTestimonials from "../components/ClientTestimonials";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions";
import HeroImageCarousel from "../components/HeroImageCarousel";
import InfoSection from "../components/InfoSection";
import WhyChooseUsSection from "../components/WhyChooseUsSection";
import { sliderContentsHomePage } from "../data/herosectionsData";
import { Helmet } from "react-helmet-async";

const Home = () => {
	return (
		<>
			{/* use react-helemt-async for seo optimization */}
			<Helmet>
				<title>
					ONS Solutions: Your Trusted Partner for Hotel Cleaning and Hospitality
					Services
				</title>
				<meta
					name="description"
					content="Professional hotel cleaning & hospitality services for a clean & welcoming environment. Contact us for reliable solutions."
				/>
				<meta
					name="keywords"
					content="ONS Solutions, hotel cleaning, hospitality services, housekeeping, janitorial services, facility maintenance, professional cleaning, guest satisfaction"
				/>
				{/* later replace the actual URL of the website */}
				<link rel="canonical" href="https://onssolution.netlify.app/" />

				{/* add Open Graph meta tags for social media sharing */}
				<meta property="og:title" content="ONS Solutions" />
				<meta property="og:type" content="website" />
				<meta
					name="og:description"
					content="Professional hotel cleaning & hospitality services for a clean & welcoming environment. Contact us for reliable solutions."
				/>
				<meta
					property="og:image"
					content="https://onssolution.netlify.app/og-image.jpg"
				/>
				<meta property="og:url" content="https://onssolution.netlify.app/" />

				{/* twitter meta tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:title"
					content="ONS Solutions: Your Trusted Partner for Hotel Cleaning and Hospitality Services"
				/>
				<meta
					name="twitter:description"
					content="Professional hotel cleaning & hospitality services for a clean & welcoming environment. Contact us for reliable solutions."
				/>
				<meta
					name="twitter:image"
					content="https://onssolution.netlify.app/og-image-twitter.jpg"
				/>
			</Helmet>

			<HeroImageCarousel sliderContents={sliderContentsHomePage} />
			<InfoSection />
			<WhyChooseUsSection />
			<ClientTestimonials />
			<FrequentlyAskedQuestions />
		</>
	);
};

export default Home;
