export const footerlinks = [
	{
		title: "About Us",
		links: [
			{ name: "Our Mission and Values", url: "/about/#mission" },
			{ name: "Who we are", url: "#" },
			{ name: "Who we serve", url: "#" },
			{ name: "Who choose ONS", url: "#" },
			{ name: "Privacy Policy", url: "#" },
		],
	},
	{
		title: "Our Services",
		links: [
			{ name: "Room Cleaning", url: "#" },
			{ name: "Upholstery Cleaning", url: "#" },
			{ name: "Post Construction Cleaning", url: "#" },
			{ name: "Pest Control", url: "#" },
			{ name: "Carpet Steam Cleaning", url: "#" },
		],
	},
];
