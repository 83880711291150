import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./index.css";
import About from "./pages/About";
import Services from "./pages/Services";
import Carrers from "./pages/Carrers";
import Contacts from "./pages/Contacts";
import ErrorPage from "./pages/ErrorPage";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/Home";
import JobDetails from "./pages/JobDetails";

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/about",
				element: <About />,
			},
			{
				path: "/services",
				element: <Services />,
			},
			{
				path: "/carrers",
				element: <Carrers />,
			},
			{ path: "/carrers/:designation", element: <JobDetails /> },
			{
				path: "/contacts",
				element: <Contacts />,
			},
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
reportWebVitals();
