import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

import { v4 as uuidv4 } from "uuid";

const HeroImageCarousel = ({ sliderContents }) => {
	return (
		<>
			{/* swiper slide */}
			<Swiper
				centeredSlides={true}
				loop={true}
				autoplay={{
					delay: 3000,
					disableOnInteraction: true,
				}}
				pagination={{
					clickable: true,
				}}
				modules={[Autoplay, Pagination]}
				className="mySwiper"
			>
				{sliderContents.map((content) => (
					<SwiperSlide key={uuidv4()}>
						<div className="relative">
							<img
								src={content.img}
								alt={content}
								className="w-full h-[600px] brightness-[0.2] object-cover bg-primaryDark"
							/>
							<div className="hidden sm:block px-4 lg:px-0 absolute top-72 left-[6%]">
								<h1 className="uppercase text-5xl 2xl:text-6xl font-bold text-white">
									{content.title}
								</h1>
								<ul className="mt-2 2xl:mt-6 max-w-4xl uppercase text-2xl 2xl:text-3xl font-bold text-primary">
									{content.lists.map((list) => (
										<li key={uuidv4()} className="py-2 2xl:py-4">
											{list}
										</li>
									))}
								</ul>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<hr className="border-b-8 border-primaryDark" />
		</>
	);
};

export default HeroImageCarousel;
