import React from "react";
import { Link } from "react-router-dom";

const InfoCard = ({ img, title, desc, url, urlText }) => {
	return (
		<div className="bg-primaryDark text-white max-w-xs px-4 py-8 rounded md:rounded-md flex flex-col items-center">
			<img src={img} alt="info_cards" className="w-20 h-20 object-contain" />
			<h1 className="text-xl font-medium mt-3">{title}</h1>
			<p className="py-3 text-sm">{desc}</p>
			<Link to={url} className="hover:underline hover:text-primary">
				{urlText}
			</Link>
		</div>
	);
};

export default InfoCard;
