import React, { useState } from "react";
import { FaPersonShelter } from "react-icons/fa6";
import { GrServices } from "react-icons/gr";
import { IoHome, IoMail } from "react-icons/io5";
import { RiTeamLine } from "react-icons/ri";
import { NavLink, useMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import HamburgerMenu from "./HamburgerMenu";
import NavigationMenu from "./common/NavigationMenu";

const Navbar = () => {
	const homeMatch = useMatch("/");
	const aboutMatch = useMatch("/about");
	const servicesMatch = useMatch("/services");
	const carrersMatch = useMatch("/carrers");
	const contactsMatch = useMatch("/contacts");

	const [toggleHamburgerMenu, setToggleHamburgerMenu] = useState(true);

	const navigationsData = [
		{
			icon: <IoHome />,
			path: "/",
			pageMatch: homeMatch,
			pageTitle: "Home",
		},
		{
			icon: <RiTeamLine />,
			path: "about",
			pageMatch: aboutMatch,
			pageTitle: "About",
		},
		{
			icon: <GrServices />,
			path: "services",
			pageMatch: servicesMatch,
			pageTitle: "Services",
		},
		{
			icon: <FaPersonShelter />,
			path: "carrers",
			pageMatch: carrersMatch,
			pageTitle: "Carrers",
		},
		{
			icon: <IoMail />,
			path: "contacts",
			pageMatch: contactsMatch,
			pageTitle: "Contacts",
		},
	];

	return (
		<div className="fixed top-0 left-0 z-50 w-full">
			<div className="max-w-6xl mx-auto px-4 h-24 md:h-32 flex items-center justify-between bg-primaryDark">
				{/* brand logo */}
				<div className="">
					<NavLink to="/">
						<img
							src="./logo.png"
							alt="brand_logo"
							className="-ml-7 lg:-ml-4 w-32 h-20 lg:h-32 object-contain"
						/>
						{/* <h1 className="text-white text-4xl">ONS Solutions</h1> */}
					</NavLink>
				</div>
				{/* navlinks */}
				<nav>
					{/* nav menu for desktop & tablet devices */}
					<ul className="hidden md:flex items-center flex-wrap lg:gap-x-8 uppercase text-lg 2xl:text-xl font-normal">
						{navigationsData.map((navigation) => (
							<NavigationMenu
								key={uuidv4()}
								// no need to pass icon for large devices
								// icon={navigation.icon}
								path={navigation.path}
								pageMatch={navigation.pageMatch}
								pageTitle={navigation.pageTitle}
								toggleHamburgerMenu={toggleHamburgerMenu}
								setToggleHamburgerMenu={setToggleHamburgerMenu}
							/>
						))}
					</ul>

					{/* hamburger menu for mobile devices */}
					<HamburgerMenu
						toggleHamburgerMenu={toggleHamburgerMenu}
						setToggleHamburgerMenu={setToggleHamburgerMenu}
						navigationsData={navigationsData}
					/>
				</nav>
			</div>
		</div>
	);
};

export default Navbar;
