import React from "react";
import Paragraph from "./Paragraph";
import { v4 as uuidv4 } from "uuid";

const ContactCard = ({ icon, heading, details }) => {
	return (
		<div className="flex items-start gap-4">
			<div className="w-16 h-16 rounded-full bg-primaryDark flex items-center justify-center">
				<i className="text-primary text-2xl">{icon}</i>
			</div>
			<div>
				<h3 className="text-xl sm:text-2xl font-semibold">{heading}</h3>
				<ul>
					{details.map((detail) => (
						<li key={uuidv4()}>
							<Paragraph text={detail} />
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ContactCard;
