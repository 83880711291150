import React from "react";

const TextAreaField = ({ id, label, handleChange, ...rest }) => {
	return (
		<div>
			<label htmlFor={id} className="text-base 2xl:text-xl">
				{label}
			</label>
			<textarea
				name="message"
				id="message"
				onChange={handleChange}
				{...rest}
				rows="5"
				className="w-full mt-1 p-4 text-base 2xl:text-xl text-primaryDark rounded-md border-2 border-gray-500 outline-none shadow-stone-200 shadow-md focus:border-none focus:ring-4 focus:ring-primary"
			></textarea>
		</div>
	);
};

export default TextAreaField;
