export const testimonials = [
	{
		name: "Sarah Thompson, Hotel Manager, ABC Hotel",
		date: "2022-04-12",
		img: "./images/testimonials/person-1.jpg",
		experience:
			"The hotel cleaning services from ONS Solutions exceeded our expectations. They were prompt, professional, and left our hotel sparkling clean. We received positive feedback from our guests, and we will definitely continue to use their services.",
	},
	{
		name: "Michael Johnson, Operations Manager, XYZ Resort",
		date: "2022-02-17",
		img: "./images/testimonials/person-2.jpg",
		experience:
			"We are extremely pleased with the hotel cleaning services provided by ONS Solutions. Their attention to detail is exceptional, and our hotel has never looked better. The team is reliable, efficient, and friendly. Highly recommended!",
	},
	{
		name: "Emily Wilson, Director of Operations, PQR Inn",
		date: "2021-10-20",
		img: "./images/testimonials/person-3.jpg",
		experience:
			"ONS Solutions has been our trusted partner for hotel cleaning services. Their staff is highly professional, and they always go above and beyond to ensure our hotel is spotless. We appreciate their commitment to excellence.",
	},
	{
		name: "David Anderson, General Manager, LMN Suites",
		date: "2021-05-10",
		img: "./images/testimonials/person-4.jpg",
		experience:
			"We are impressed with the hotel cleaning services provided by ONS Solutions. They consistently deliver top-notch results and have transformed the cleanliness of our hotel. Their team is reliable and attentive to our specific needs. We couldn't be happier!",
	},
	{
		name: "Jennifer Lee, Hotel Owner, UVW Resort",
		date: "2020-05-30",
		img: "./images/testimonials/person-5.png",
		experience:
			"ONS Solutions has made a significant difference in the cleanliness standards of our hotel. Their cleaning services are thorough and efficient. We appreciate their professionalism and attention to detail. Highly recommended!",
	},
];
