import React from "react";

const WhoWeAre = () => {
	return (
		<section className="max-w-6xl mx-auto px-4 xl:px-0 pt-7 md:pt-14">
			<div className="md:flex gap-x-4">
				<div className="md:w-1/2">
					<h2 className="text-primaryDark text-3xl sm:text-4xl font-semibold sm:font-bold">
						Who We Are
					</h2>
					<p className="text-base 2xl:text-xl py-5">
						ONS Solution's success story began in 1983 in Kansas City, Missouri.
						Our founder, John Knoepker, launched the company with a vision for
						reshaping the cleaning service industry.
					</p>
					<p className="text-base 2xl:text-xl py-5">
						The ONS Solutions is the premier service provider to the
						hospitality, gaming, healthcare, education, and corporate catering
						industries. We deliver best-in-class cleaning, staffing, and managed
						labor services using exclusively legal, compliant labor. We know
						what it takes to make our clients succeed.
					</p>
					<p className="text-base 2xl:text-xl py-5">
						Today, we are one of the largest and most trusted cleaning and
						hospitality staffing companies in the United States. We provide an
						outstanding level of service and cleanliness for our resort, hotel,
						spa, restaurant, and commercial clients, consistently exceeding
						expectations.
					</p>
				</div>
				<div className="md:w-1/2">
					<img
						src="./images/about-us/house-keeping.webp"
						alt="house-keeper"
						className="w-full h-full object-cover"
					/>
				</div>
			</div>
		</section>
	);
};

export default WhoWeAre;
