import React from "react";

const ServiceBenefit = ({ title, desc }) => {
	return (
		<div className="bg-primaryDark text-white p-5 rounded-md">
			<div className="flex gap-x-5">
				<img
					src="./images/why-choose-us-section/tick.png"
					alt="our_service_benefits"
					className="w-10 h-10 object-contain"
				/>
				<div>
					<h3 className="text-xl sm:text-2xl font-semibold">{title}</h3>
					<p className="pt-2 text-sm">{desc}</p>
				</div>
			</div>
		</div>
	);
};

export default ServiceBenefit;
