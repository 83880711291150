export const jobOpenings = [
	{
		designation: "Housekeeping Supervisor",
		openings: 2,
		deadline: "January 31, 2024",
		postedOn: "January 01, 2024",
		location: "Abu Dhabi, UAE",
		desc: [
			"Supervise and manage housekeeping operations, ensuring cleanliness and sanitation standards are met.",
			"Train and coordinate housekeeping staff, assign duties, and maintain inventory of cleaning supplies.",
			"Monitor and inspect guest rooms and public areas to ensure high-quality standards are maintained.",
			"Develop and implement cleaning procedures and protocols to optimize efficiency and productivity.",
			"Address guest requests and concerns promptly, maintaining a high level of customer satisfaction.",
			"Collaborate with other departments to ensure seamless operations and guest satisfaction.",
			"Manage budgets, control costs, and ensure adherence to health and safety regulations.",
		],
		requirements: [
			"Previous experience in a supervisory or managerial role within the housekeeping department.",
			"Strong knowledge of cleaning techniques, equipment, and chemicals.",
			"Excellent organizational and leadership skills.",
			"Ability to multitask, prioritize, and delegate tasks effectively.",
			"Exceptional attention to detail and quality standards.",
			"Excellent communication and interpersonal skills.",
			"Familiarity with health and safety regulations in the hospitality industry.",
		],
		qualifications: [
			"High school diploma or equivalent required. Additional certification in housekeeping or hospitality management is a plus.",
			"Proven experience in housekeeping or a related field, preferably in a hotel or resort setting.",
			"Demonstrated ability to effectively manage a team and ensure high standards of cleanliness and guest satisfaction.",
			"Proficiency in using housekeeping software and other relevant computer applications.",
		],
	},
	{
		designation: "Room Attendant",
		openings: 5,
		deadline: "February 15, 2024",
		postedOn: "January 01, 2024",
		location: "Abu Dhabi, UAE",
		desc: [
			"Clean and maintain guest rooms, ensuring cleanliness, tidiness, and a welcoming environment.",
			"Change bed linens, replace towels, restock supplies, and perform routine cleaning tasks.",
			"Respond to guest requests and inquiries, providing excellent customer service at all times.",
			"Report any maintenance issues or damages to the appropriate departments.",
			"Adhere to safety and security procedures to ensure the wellbeing of guests and staff.",
			"Collaborate with housekeeping team members to ensure efficient operations.",
			"Maintain a professional and courteous demeanor when interacting with guests.",
		],
		requirements: [
			"Previous experience in housekeeping or a similar role is preferred.",
			"Familiarity with cleaning techniques, equipment, and chemicals.",
			"Attention to detail and the ability to work independently.",
			"Strong customer service and communication skills.",
			"Ability to handle physically demanding tasks, such as lifting heavy objects and standing for long periods.",
			"Knowledge of safety and sanitation standards in a hospitality environment.",
		],
		qualifications: [
			"High school diploma or equivalent required.",
			"Previous experience in a hotel or resort setting is a plus.",
			"Knowledge of proper handling and disposal of cleaning chemicals.",
			"Ability to follow instructions and adhere to established procedures.",
		],
	},
	{
		designation: "Janitorial Staff",
		openings: 3,
		deadline: "March 10, 2024",
		postedOn: "January 01, 2024",
		location: "Abu Dhabi, UAE",
		desc: [
			"Perform cleaning and maintenance tasks to ensure cleanliness and hygiene in various facilities.",
			"Sweep, mop, vacuum, and clean floors, corridors, and common areas.",
			"Empty trash bins, dispose of waste properly, and maintain recycling programs.",
			"Clean and sanitize restrooms, replenish supplies, and ensure a tidy and functional environment.",
			"Handle cleaning equipment and chemicals safely and follow proper cleaning procedures.",
			"Report any maintenance or repair needs to the appropriate personnel.",
			"Assist with setting up and arranging furniture or equipment for events or meetings.",
		],
		requirements: [
			"Previous experience in janitorial or custodial work is preferred.",
			"Familiarity with cleaning techniques, equipment, and chemicals.",
			"Ability to handle physically demanding tasks, such as lifting and operating cleaning equipment.",
			"Knowledge of safety and sanitation standards in a commercial or hospitality environment.",
			"Good time management and organizational skills.",
			"Ability to work independently and as part of a team.",
		],
		qualifications: [
			"High school diploma or equivalent required.",
			"Previous janitorial or custodial experience is a plus.",
			"Knowledge of proper handling and use of cleaning chemicals and equipment.",
			"Ability to follow instructions and adhere to established cleaning procedures.",
		],
	},
	{
		designation: "Housekeeping Assistant",
		openings: 4,
		deadline: "March 15, 2024",
		postedOn: "January 01, 2024",
		location: "Abu Dhabi, UAE",
		desc: [
			"Assist with various housekeeping tasks, including cleaning and maintaining guest rooms and public areas.",
			"Change bed linens, replace towels, restock supplies, and perform routine cleaning tasks.",
			"Assist with the setup and arrangement of furniture or equipment for events or meetings.",
			"Empty trash bins, dispose of waste properly, and maintain recycling programs.",
			"Report any maintenance issues or damages to the appropriate departments.",
			"Adhere to safety and security procedures to ensure the wellbeing of guests and staff.",
			"Collaborate with housekeeping team members to ensure efficient operations.",
		],
		requirements: [
			"Previous experience in housekeeping or a similar role is preferred.",
			"Familiarity with cleaning techniques, equipment, and chemicals.",
			"Attention to detail and the ability to work independently.",
			"Ability to handle physically demanding tasks, such as lifting heavy objects and standing for long periods.",
			"Good time management and organizational skills.",
			"Knowledge of safety and sanitation standards in a hospitality environment.",
		],
		qualifications: [
			"High school diploma or equivalent required.",
			"Previous experience in a hotel or resort setting is a plus.",
			"Knowledge of proper handling and disposal of cleaning chemicals.",
			"Ability to follow instructions and adhere to established procedures.",
		],
	},
	{
		designation: "Laundry Attendant",
		openings: 2,
		deadline: "April 01, 2024",
		postedOn: "January 01, 2024",
		location: "Abu Dhabi, UAE",
		desc: [
			"Sort, wash, dry, fold, and distribute linens and towels according to established procedures.",
			"Operate laundry equipment and perform routine maintenance and cleaning of machines.",
			"Inspect linens for stains, tears, or damage and report any issues to the appropriate departments.",
			"Maintain inventory of clean and dirty linens, ensuring an adequate supply at all times.",
			"Collaborate with other departments to ensure timely delivery and pickup of laundry items.",
			"Adhere to safety and sanitation standards in handling and storing linens.",
			"Maintain a clean and organized laundry area.",
		],
		requirements: [
			"Previous experience in laundry operations or a similar role is preferred.",
			"Knowledge of laundry equipment and proper handling of linens.",
			"Good time management and organizational skills.",
			"Ability to handle physically demanding tasks, such as lifting and operating laundry machines.",
			"Attention to detail and the ability to work independently.",
			"Knowledge of safety and sanitation standards in a hospitality environment.",
		],
		qualifications: [
			"High school diploma or equivalent required.",
			"Previous experience in a hotel or resort laundry is a plus.",
			"Knowledge of proper handling and care of different types of fabrics.",
			"Ability to follow instructions and adhere to established laundry procedures.",
		],
	},
];
