import React from "react";
import { BsTelephone } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { MdCalendarMonth, MdLocationPin } from "react-icons/md";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { socialmedialinks } from "../../data/socailmedialinks";
import FooterLinks from "../FooterLinks";
import IconWithName from "../common/IconWithName";
import SocialMediaLinks from "../common/SocialMediaLinks";

const Footer = () => {
	return (
		<footer className="w-full bg-primaryDark mt-6 py-14 text-gray-100">
			{/* all details */}
			<div className="max-w-6xl mx-auto px-4 flex justify-start xl:justify-between items-start flex-wrap gap-y-12">
				{/* first col */}
				<div className="max-w-xs -mt-1 pr-10">
					<Link to="/">
						<h2 className="mb-3 text-3xl font-semibold tracking-wide leading-8">
							ONS Services
						</h2>
					</Link>
					<p className="text-sm 2xl:text-xl">
						ONS Facility Services is an UAE owned company based in Ajman that
						provides commercial cleaning services and facility maintenance
						services across UAE.
					</p>
				</div>

				{/* second & third col */}
				<FooterLinks />

				{/* fourth col */}
				<div className="">
					<h1 className="text-xl font-bold leading-6">Corporate Office</h1>
					<IconWithName
						icon={<MdLocationPin size="20px" />}
						name="Suite 69, 42 Manilla St"
					/>
					<IconWithName icon={<BsTelephone size="18px" />} name="1234 567 89" />
					<IconWithName
						icon={<IoMdMail size="20px" />}
						name="support@onsfacilityservices.uae"
					/>
					<div className="mt-4">
						<h1 className="text-xl font-bold leading-6">Opening Hours</h1>
						<IconWithName
							icon={<MdCalendarMonth size="20px" />}
							name="24/7 Monday - Friday"
						/>
					</div>
				</div>
			</div>

			{/* copyright section */}
			<div className="max-w-6xl mx-auto mt-12 px-4 flex items-center gap-x-3 gap-y-5 justify-center flex-wrap sm:justify-between">
				<ul className="flex gap-x-5 item-center">
					{socialmedialinks.map((link) => (
						<li key={uuidv4()}>
							<SocialMediaLinks
								url={link.url}
								title={link.title}
								icon={link.icon}
							/>
						</li>
					))}
				</ul>
				<div className="uppercase text-center">
					Copyright 2023 ONS Facility Services, All rights Reserved
				</div>
			</div>
		</footer>
	);
};

export default Footer;
