import React from "react";
import { v4 as uuidv4 } from "uuid";
import SliderCard from "./common/SliderCard";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "../styles/swipercarousel.css";

const FeatureSlider = ({ heading, data }) => {
	return (
		<section className="max-w-6xl mx-auto px-4 my-7 md:my-14 py-7 md:py-14 bg-gray-100">
			<h2 className="text-primaryDark text-3xl sm:text-4xl font-semibold sm:font-bold">
				{heading}
			</h2>
			<div className="mt-5">
				<Swiper
					slidesPerView="auto"
					spaceBetween={20}
					loop={true}
					autoplay={{
						delay: 15000,
						disableOnInteraction: true,
					}}
					pagination={{
						clickable: true,
					}}
					navigation={true}
					modules={[Autoplay, Pagination, Navigation]}
					id="who_we_serve"
				>
					{data.map((item) => (
						<SwiperSlide key={uuidv4()}>
							<SliderCard key={uuidv4()} title={item.title} desc={item.desc} />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

export default FeatureSlider;
