import { useField } from "formik";
import React from "react";
import { v4 as uuidv4 } from "uuid";

const SelectField = ({ label, handleChange, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<div className="w-full flex flex-col">
			<label htmlFor={props.id || props.name} className="text-base 2xl:text-xl">
				{label}
			</label>
			<select
				onChange={handleChange}
				{...field}
				{...props}
				className={`${
					meta.touched && meta.error ? `border-2 border-[red]` : ``
				} h-12 mt-1 pl-4 text-black text-base 2xl:text-xl rounded-md border-2 border-gray-500 outline-none shadow-stone-200 shadow-md focus:border-none focus:ring-4 focus:ring-primary`}
			>
				{props.options.map((option) => (
					<option key={uuidv4()} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
			{meta.touched && meta.error ? (
				<div className="text-[red] text-base relative top-2">{meta.error}</div>
			) : null}
		</div>
	);
};

export default SelectField;
