import React from "react";
import { footerlinks } from "../data/footerlinks";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const FooterLinks = () => {
	const navigate = useNavigate();
	function handleClick(url) {
		navigate(url);
	}
	return (
		<>
			{footerlinks.map((link) => (
				<div key={uuidv4()} className="pr-10">
					<h1 className="text-xl font-bold leading-6">{link.title}</h1>
					<ul className="pt-2">
						{link.links.map((link) => (
							<li
								key={uuidv4()}
								className="py-1 text-sm hover:underline hover:text-primary"
							>
								<button onClick={() => handleClick(link.url)}>
									{link.name}
								</button>
								{/* <a href={link.url}>{link.name}</a> */}
							</li>
						))}
					</ul>
				</div>
			))}
		</>
	);
};

export default FooterLinks;
