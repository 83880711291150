import React from "react";
import { v4 as uuidv4 } from "uuid";
import Paragraph from "../components/common/Paragraph";

export const JobDescriptionCard = ({ heading, iterable }) => {
	return (
		<>
			<h2 className="text-primary text-lg md:text-3xl font-semibold">
				{heading}
			</h2>
			<ul className="mt-6 list-disc">
				{iterable.map((content) => (
					<li className="ml-4 pb-2" key={uuidv4()}>
						<Paragraph text={content} color="text-lightWhite" />
					</li>
				))}
			</ul>
		</>
	);
};
